<template>
  <div v-if="idomCombClone">
    <b style="font-size: 18px">{{ idomCombClone.title }}</b>
    <div class="text-mute line m-b-xs">
      <div class="m-r-md">
        等级：{{ idomCombClone.minLevel }} - {{ idomCombClone.maxLevel }}
      </div>
      <div class="m-r-md">
        接龙次数：{{
          idomCombClone.contentMaterialList[0].materialList.length - 1
        }}
      </div>
      <div class="m-r-md" v-if="idomCombClone.gameReadConfig">跟读遍数：{{ idomCombClone.gameReadConfig.readCount }}</div>
      <div class="m-r-md" v-if="idomCombClone.subTime">
        提交时间：<Time :time="idomCombClone.subTime" type="datetime" />
      </div>
      <div class="m-r-md" v-if="idomCombClone.createTime">
        创建时间：{{ idomCombClone.createTime }}
      </div>
      <div class="m-r-md" v-if="idomCombClone.workFlowMap">
        <div v-if="idomCombClone.workFlowMap.downShelfTime">
          下架时间：<Time
            :time="idomCombClone.workFlowMap.downShelfTime"
            type="datetime"
          />
        </div>
        <div v-else>
          发布时间：<Time
            :time="idomCombClone.workFlowMap.publishTime"
            type="datetime"
          />
        </div>
      </div>
      <span>标签：</span>
      <TagsInput
        size="default"
        :tags="idomCombClone.contentMaterialList[0].materialList[0].tagList"
        :editable="tagsEditing"
      />
    </div>

    <div
      v-for="(sub,
      i) in idomCombClone.contentMaterialList[0].materialList.slice(1)"
      :key="i"
      class="line"
    >
      <div style="width: 12em">{{ sub.content }}</div>
      <div class="text-mute">标签：</div>
      <TagsInput size="default" :tags="sub.tagList" :editable="tagsEditing" />
    </div>

    <slot name="progress">
      <ProcessSteps
        style="margin: 6px 0; font-size: 16px; width: 700px"
        :taskName="idomCombClone.nowTaskName || idomCombClone.taskName"
      />
    </slot>
    <slot name="tags-editor">
      <span v-if="tagsEditing">
        <Button @click="cancelEdit">取消</Button>
        <Button type="primary" @click="submitTags">确定</Button>
      </span>
      <Button v-else @click="startEdit" type="primary">编辑标签</Button>
    </slot>
    <slot name="after-tags-editor"></slot>
    <div
      class="text-error"
      v-if="idomCombClone.clip && idomCombClone.taskName === 5"
    >
      音频匹配失败：部分匹配失败，请点击查看进入修改
    </div>
  </div>
  <div v-else></div>
</template>

<script>
import { updateIdiomTags } from "./services";
export default {
  props: {
    idomComb: {},
  },
  data() {
    return {
      tagsEditing: false,
      idomCombClone: null,
    };
  },
  methods: {
    startEdit() {
      this.tagsEditing = true;
      this.idomCombClone = JSON.parse(JSON.stringify(this.idomComb));
    },
    cancelEdit() {
      this.tagsEditing = false;
      this.idomCombClone = this.idomComb;
    },
    submitTags() {
      updateIdiomTags(this.idomCombClone).then((res) => {
        Object.assign(this.idomComb, this.idomCombClone);
        this.tagsEditing = false;
      });
    },
  },
  beforeMount() {
    this.idomCombClone = this.idomComb;
  },
};
</script>

<style scoped>
.line {
  display: flex;
  align-items: center;
  min-height: 2.35em;
}
</style>