<template>
  <div class="flying-form">
    <div class="vui-row">
      <div class="vui-col-fluid vui-mr20">
        <div class="title">指定等级</div>
        <InputNumber
          :min="0"
          :max="form.grade.max === null ? 1000 : form.grade.max"
          v-model="form.grade.min"
          :active-change="false"
          style="width: 80px"
        />-
        <InputNumber
          :min="form.grade.min"
          :max="1000"
          :active-change="false"
          v-model="form.grade.max"
          style="width: 80px"
        />
      </div>
    </div>
    <div class="vui-row">
      <div class="vui-col">
        <p>指定等级必须处于已设置阅读模式的等级之内</p>
      </div>
    </div>
    <div class="vui-row vui-row-wrap">
      <div class="vui-col">
        <div class="title">标题</div>
        <Input v-model="form.question" />
      </div>
    </div>
    <div
      class="poem-item vui-row"
      v-for="(poem, index) in form.poems"
      :key="index"
    >
      <div class="vui-col-75">
        <div class="title">诗词</div>
        <Input v-model="poem.content" />
        <div
          v-if="index === form.poems.length - 1"
          class="text-success hover vui-mt5"
          style="font-size: 12px"
          title="添加新的诗词"
          @click="addPoem"
        >
          <Icon style="font-size: 14px" type="md-add-circle" />
          <span>诗词</span>
        </div>
      </div>
      <div class="vui-col-25">
        <div class="title">标签（，分割）</div>
        <Input v-model="poem.tags" />
      </div>
      <div class="vui-col-fluid vui-align-self-center">
        <div
          :style="{
            visibility: form.poems.length !== 1 ? 'visible' : 'hidden',
          }"
          class="text-error hover"
          style="font-size: 12px"
          title="删除该诗词"
          @click="removePoem(index)"
        >
          <Icon style="font-size: 14px" type="md-remove-circle" />
        </div>
      </div>
    </div>

    <div style="text-align: right; padding: 1em 0">
      <Button type="text" style="margin-right: 1em" @click="onCancel"
        >取消</Button
      >
      <Button type="primary" @click="onSubmit">确定</Button>
    </div>
    <Spin fix v-if="uploading"></Spin>
  </div>
</template>

<script>
import { createGame } from "./services";

export default {
  props: {
    data: {},
  },
  data() {
    return {
      uploading: false,
      form: {
        id: "",
        processId: "",
        parentId: "",
        contentType: "cnFlying",
        grade: {
          min: null,
          max: null,
        },
        question: "",
        poems: [],
      },
    };
  },
  watch: {
    data: {
      handler(val) {
        if (val) {
          this.form.id = val.id;
          this.form.processId = val.processId;
          this.form.contentType = val.contentType;
          this.form.grade.min = val.minLevel;
          this.form.grade.max = val.maxLevel;
          this.form.question = val.title;
          this.form.poems = val.contentMaterialList[0].materialList.map(
            (x) => ({
              content: x.content,
              tags: x.tagList.join(","),
            })
          );
        } else {
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    if (!this.data) {
      this.addPoem();
    }
  },
  methods: {
    // 添加诗词
    addPoem() {
      this.form.poems.push({
        content: "",
        tags: "",
      });
    },

    removePoem(index) {
      this.form.poems.splice(index, 1);
    },

    verify() {
      // 校验数据
      if (
        this.form.grade.min === null ||
        this.form.grade.max === null ||
        this.form.grade.min > this.form.grade.max
      ) {
        this.$Message.warning("请填写指定等级");
        return false;
      }
      if (!this.form.question) {
        this.$Message.warning("请填写问题标题");
        return false;
      }
      if (!this.form.poems.length) {
        this.$Message.warning("请至少填写一诗词");
        return false;
      } else {
        let msg = "";
        let valid = true;
        for (let poem of this.form.poems) {
          if (!poem.content) {
            valid = false;
            msg = "诗词内容填写不完整";
            break;
          }
        }
        if (!valid) {
          this.$Message.warning(msg);
          return false;
        }
        return true;
      }
    },

    onSubmit() {
      if (this.verify()) {
        let data = {
          parentId: this.form.id,
          parentProcessId: this.form.processId,
          title: this.form.question,
          gameType: 2,
          contentType: this.form.contentType,
          minLevel: this.form.grade.min,
          maxLevel: this.form.grade.max,
          pass: 9,
          contentMaterialList: [
            {
              materialList: this.form.poems.map((x) => ({
                ...x,
                tagList: x.tags.split(","),
              })),
            },
          ],
        };
        this.uploading = true;
        data.taskName = 0;
        createGame(data)
          .then(() => {
            if (data.parentId) {
              this.$Message.success("编辑成功！");
            } else {
              this.$Message.success("新增成功！");
            }
            this.$emit("close", true);
          })
          .catch((err) => {
            this.$Message.warning(err.message);
          })
          .finally(() => {
            this.uploading = false;
          });
      }
    },

    onCancel() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.poem-item {
  position: relative;
  margin-bottom: 10px;
}

.title {
  margin-bottom: 10px;
}

.title,
.title .ant-checkbox-wrapper {
  font-size: 13px;
  color: #000;
  font-weight: 500;
}

p {
  font-size: 13px;
  color: #999;
  font-weight: 500;
}
</style>
