<template>
  <div class="poem-item">
    <div class="poem-title">{{data_.title}}</div>
    <dl class="poem-level">
      <dt>等级：{{data_.minLevel}}-{{data_.maxLevel}}</dt>
      <!--<dd>模式：跟读遍数：{{data.title}}</dd>-->
      <dt v-if="data_.subTime">提交时间：<Time :time="data_.subTime" type="datetime"/></dt>
      <dt class="m-r-md" v-if="data_.createTime">创建时间：{{data_.createTime}}</dt>
      <dt class="m-r-md" v-if="data_.workFlowMap">
        <div v-if="data_.workFlowMap.downShelfTime">下架时间：<Time :time="data_.workFlowMap.downShelfTime" type="datetime"/></div>
        <div v-else>发布时间：<Time :time="data_.workFlowMap.publishTime" type="datetime"/></div>
      </dt>
    </dl>
    <dl class="poem-box" v-for="(item,i) in data_.contentMaterialList[0].materialList" :key="i">
      <dt>{{i + 1}}</dt>
      <dd class="poem-content">{{item.content}}</dd>
      <dd class="poem-tags">标签：<TagsInput :tags="item.tagList" :editable="tagsEditing" /></dd>
    </dl>
    <slot name="progress">
      <ProcessSteps
        style="margin: 6px 0;font-size: 16px;width: 700px;"
        :taskName="data_.nowTaskName||data_.taskName"
      />
    </slot>
    <template v-if="type == 0">
      <span v-if="tagsEditing">
        <Button @click="cancelEdit">取消</Button>
        <Button type="primary" @click="submitTags">确定</Button>
      </span>
      <Button v-else @click="startEdit" type="primary">编辑标签</Button>
      <template v-if="published">
        <span class="status-span active" v-if="data.status == 3">已上架</span>
        <span class="status-span" v-else>已下架<span style="padding-left:1em">下架原因：{{data.remark}}</span></span>
      </template>
    </template>
    <template v-if="type == 1">
      <div class="poem-actions">
        <Button @click="edit" type="primary">编辑</Button>
      </div>
    </template>
    <template v-if="type == 2">
      <div class="poem-actions">
        <Button @click="remove" type="primary">删除</Button>
      </div>
    </template>
    <slot name="tags-editor">
    </slot>
    <span class="text-error" v-if="data_.clip && data_.taskName === 5">音频匹配失败：部分匹配失败，请点击查看进入修改</span>
    <Modal v-model="showFlyingForm" title="编辑飞花令" footer-hide :mask-closable="false">
      <FlyingForm v-if="showFlyingForm" :data="data_" @close="onClose"/>
    </Modal>
  </div>
</template>

<script>
import FlyingForm from "./FlyingForm";
import {updateIdiomTags} from './services'

export default {
  components: { FlyingForm },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    },
    type: String,
    published: Boolean
  },
  data() {
    return {
      data_: {},
      showFlyingForm: false,
      tagsEditing:false
    };
  },
  watch: {
    data: {
      handler(val) {
        this.data_ = val;
      },
      immediate: true
    }
  },
  methods: {
    edit() {
      this.data_ = JSON.parse(JSON.stringify(this.data));
      this.showFlyingForm = true;
    },
    onCancel() {
      this.editable = false;
      this.data_ = this.data;
    },
    onClose() {
      this.showFlyingForm = false;
      this.$emit('refresh');
    },

    startEdit() {
      this.tagsEditing = true;
      this.data_ = JSON.parse(JSON.stringify(this.data));
    },
    cancelEdit(){
      this.tagsEditing = false
      this.data_ = this.data
    },
    submitTags() {
      updateIdiomTags(this.data_).then(() => {
        Object.assign(this.data, this.data_);
        this.tagsEditing = false;
      });
    }
  }
}
</script>

<style lang="less" scoped>
.poem-item {
  width: 100%;
}

.poem-title {
  line-height: 28px;
  font-size: 16px;
  font-weight: 400;
  color: #000;
}

.poem-level {
  display: flex;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 12px;
  color: #666;

  dt {
    margin-right: 30px;
  }
}

.poem-box {
  display: flex;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 400;
  color: #000;

  dt {
    margin-right: 10px;
  }
}

.poem-content {
  flex: 0 0 50%;
}

.poem-tags {
  color: #999;
  font-weight: 400;
}

.poem-actions {
  display: flex;
  align-items: center;
  margin: 15px 0 20px;

  button {
    margin-right: 10px;
  }

  p {
    font-size: 12px;
    color: #666;
  }
}

</style>
