import axios from './axios'

export function createGame(params) {
    return axios({
        data: params,
        url: "/common/content/start/submit",
        method: 'post'
    })
}

/**
* 已发布 下架
* @param {Array<{id,contentType}>} hbList
*/
export function downShelf(hbList, reMark) {
    hbList.forEach(hb => {
        hb.remark = reMark;
    });
    return axios({
        method: 'put',
        url: '/common/content/downShelfBatch',
        data: hbList
    });
}

/**
  * 已发布 上架
  * @param {Array<{id,contentType}>} hbList
  */
export function upShelf(hbList) {
    return axios({
        method: 'put',
        url: '/common/content/reUpBatch',
        data: hbList
    });
}

/**
   *
   * @param {Array<{bizId,processInstanceId,taskId,contentType}>} hblist
   * @param {string} reason
   */
export function delList(hblist, reason) {
    const data = hblist.map(ele => {
        return {
            id: ele.bizId || 'bizId',
            processId: ele.processInstanceId || 'processInstanceId',
            taskId: ele.taskId,
            contentType: ele.contentType,
            taskName: ele.taskName,
            pass: -1,
            maxLevel: ele.maxLevel,
            minLevel: ele.minLevel,
            gameType: ele.gameType,
            remark: reason
        };
    });
    return axios({
        method: 'delete',
        url: '/common/content/deleteBath',
        data
    });
}