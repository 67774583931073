import axios from './axios'
import Vue from 'vue';
import { _configType } from './gameService'
export const queryCombCombination = (params) => {
    return axios({
        method: 'get',
        url: '/cnSolitaire/solitaire/comb/query2',
        params
    }).then(res => {
        /*res.generations = []
        if (res.contentList.length)    {
            let n = res.contentList[0].length - 1
            for (let i = 0; i < n; i++) {
                let buf = new Set()
                for (let cot of res.contentList) {
                    buf.add(cot[i + 1])
                }
                res.generations.push(Array.from(buf))
            }
        }*/
        return res
    })
}
export const getHisTaskList = (params) => {
    //const { data } = require('../../../assets/json/hisTaskList.json');
    //return Promise.resolve(data);
    return axios({
        method: 'get',
        url: '/gameCommon/hisTaskList',
        params
    })
}

/**
 * 待处理列表
 * @param {*} params
 */
export const gemeTaskList = (params) => {
    return axios({
        method: 'get',
        url: '/gameCommon/taskList',
        params
    }).then(res => {
        res.rows.forEach(ele => {
            ele.realName ??= null
            ele.userName ??= null
        })
        return res;
    })
}
export const updateIdiomTags = (data) => {
    data.id = data.id || data.bizId;
    data.bizId = data.bizId || data.id
    return axios({
        method: 'put',
        url: '/gameCommon/update',
        data
    }).catch(err => {
        Vue.prototype.$Message.warning('[后台提示]' + err);
        throw err
    })
}
export const getRecycleList = (params) => {
    return axios({
        method: 'get',
        url: '/gameCommon/disusedList',
        params
    })
}
export const getPublishedList = (params) => {
    params.gameType = _configType[params.contentType]
    return axios({
        method: 'get',
        url: '/gameCommon/publishList',
        params
    })
}