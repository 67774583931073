import axios from './gameAxios'
export const _configType = { "cnFlying": 2, "cnIdiomComb": 3 ,'cn':-1}
export const addConfig = (data) => {
    data.type = _configType[data.type] || data.type
    return axios({
        method: 'post',
        url: '/GameReadConfig/add',
        data
    }, true)
}
export const modifyConfig = (data) => {
    data.type = _configType[data.type] || data.type
    return axios({
        method: 'put',
        url: '/GameReadConfig/update',
        data
    }, true)
}
export const getConfigs = (type, minLevel, maxLevel, id) => {
    return axios({
        method: 'get',
        url: '/GameReadConfig/page',
        params: {
            pageNo: 1,
            pageSize: 10000,
            minLevel,
            maxLevel,
            id,
            type: _configType[type]
        }
    }, true)
}
export const deleteConfig = (id) => {
    return axios({
        method: 'delete',
        url: `/GameReadConfig/delete/${id}`
    }, true)
}


/**
 *
 * @param {*} minLevel
 * @param {*} maxLevel
 * @param {*} idiom1st
 * @param {Array} idiomsFrom2nd
 * @param {Object} tagListMap
 */
export const getSubmitCombData = (minLevel, maxLevel, idiom1st, idiomsFrom2nd, tagListMap) => {
    let buf = [idiom1st]
    let i = 0;
    while (idiomsFrom2nd[i]) {
        buf.push(idiomsFrom2nd[i++])
    }
    buf = buf.map(ele => ({ content: ele, tagList: tagListMap[ele] }))
    let data = {
        title: idiom1st, //
        gameType: _configType["cnIdiomComb"],
        contentType: "cnIdiomComb",
        minLevel,
        maxLevel,
        contentMaterialList: [
            {
                materialList: buf
            }
        ],
        pass: 10
    };
    return data
}


